/**
 * Load Google base event listeners
 */

// Pixel load event listener
jQuery(document).on("pmwLoadPixels", function () {

	if (typeof wpmDataLayer?.pixels?.google?.state === "undefined") {
		if (wpm.googleConfigConditionsMet({log: true})) {
			wpm.loadGoogle()
		} else {
			wpm.consent.logSuppressedPixel("statistics", "Google Analytics / Google Ads")
		}
	}
})

document.addEventListener("pmw:consent:update", ({detail}) => {

	const consent = detail

	wpm.updateGoogleConsentMode(consent)
})
